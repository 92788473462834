import { Col, Flex, Image, Layout, Pagination, Row, Space, Spin, Typography } from "antd"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { MainLayout } from "../../layout/MainLayout"
import { MenuType } from "../../type/MenuType"
import { Header } from "./Header"
import { PaginationModel } from "../../model/PaginationModel"
import { BlogModel } from "../../model/BlogModel"
import { Errors } from "../../contexts/ErrorContext"
import { BlogService } from "../../service/BlogService"

export const Blog = () => {
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState<boolean>(true)
    const [page, setPage] = useState<PaginationModel<BlogModel>>()
    const [activate, setActivate] = useState<string>('전체')

    const menus = [
        '전체',
        '퍼포먼스',
        '콘텐츠',
        '개발',
        '기타'
    ]

    const items = [
        {
            "writingSeq": 292,
            "writingCategoryKoreaName": "파트너 성장",
            "writingCategoryEnglishName": "Grow Together",
            "writingCategoryEtcName": "",
            "writingTitle": "함께가치를 위한 배민다운 이야기",
            "writingSubTitle": "가치경영보고서 <배민스토리 2024> 가치경영보고서 <배민스토리 2024> 가치경영보고서 <배민스토리 2024> 가치경영보고서 <배민스토리 2024> 가치경영보고서 <배민스토리 2024> 가치경영보고서 <배민스토리 2024> 가치경영보고서 <배민스토리 2024> 가치경영보고서 <배민스토리 2024>",
            "writingImagePath": "https://woowahan-cdn.woowahan.com/new_resources/image/writing/35103a09fe0e4287808edfb9d5d010b7.jpg",
            "writingOpenStatusCode": "PUBLIC",
            "writingLinkUrl": "",
            "writingPublishReservationYn": true,
            "writingPublishDate": "2024-08-26 08:30:00",
            "writingTemporaryYn": false,
            "writingDeleteYn": false,
            "writingDeleteDay": "2999-12-31",
            "createDate": "2024-08-22 23:18:43",
            "updateDate": "2024-08-29 09:48:46"
        },
        {
            "writingSeq": 290,
            "writingCategoryKoreaName": "파트너 성장",
            "writingCategoryEnglishName": "Grow Together",
            "writingCategoryEtcName": "",
            "writingTitle": "사장님 걱정마세요! 급여 문제는 저희가 해결할게요",
            "writingSubTitle": "2024 우아한 노무해결사",
            "writingImagePath": "https://woowahan-cdn.woowahan.com/new_resources/image/writing/65751ae45c7a40ad8fc2abe5d63a7ac1.jpg",
            "writingOpenStatusCode": "PUBLIC",
            "writingLinkUrl": "",
            "writingPublishReservationYn": false,
            "writingPublishDate": "2024-06-28 15:36:35",
            "writingTemporaryYn": false,
            "writingDeleteYn": false,
            "writingDeleteDay": "2999-12-31",
            "createDate": "2024-06-26 13:34:13",
            "updateDate": "2024-08-08 18:38:27"
        },
        {
            "writingSeq": 288,
            "writingCategoryKoreaName": "이웃과 함께",
            "writingCategoryEnglishName": "Baemin Neighbors",
            "writingCategoryEtcName": "",
            "writingTitle": "모든 아이들의 웃음 가득한 \n어린이날을 위해",
            "writingSubTitle": "어린이날 선물배달부 기부캠페인",
            "writingImagePath": "https://woowahan-cdn.woowahan.com/new_resources/image/writing/2880e89b0d9142669b945aed24f2daa8.jpg",
            "writingOpenStatusCode": "PUBLIC",
            "writingLinkUrl": "",
            "writingPublishReservationYn": false,
            "writingPublishDate": "2024-06-21 14:00:46",
            "writingTemporaryYn": false,
            "writingDeleteYn": false,
            "writingDeleteDay": "2999-12-31",
            "createDate": "2024-06-21 14:00:47",
            "updateDate": "2024-06-21 14:00:47"
        },
        {
            "writingSeq": 286,
            "writingCategoryKoreaName": "이웃과 함께",
            "writingCategoryEnglishName": "Baemin Neighbors",
            "writingCategoryEtcName": "",
            "writingTitle": "누구나 쉽게 배달앱을 사용할 수 있을 때까지!",
            "writingSubTitle": "쉬운 배달앱 사용법",
            "writingImagePath": "https://woowahan-cdn.woowahan.com/new_resources/image/writing/61b1b7d86ea24fd4918d9007d809a729.jpg",
            "writingOpenStatusCode": "PUBLIC",
            "writingLinkUrl": "",
            "writingPublishReservationYn": false,
            "writingPublishDate": "2024-05-28 14:08:39",
            "writingTemporaryYn": false,
            "writingDeleteYn": false,
            "writingDeleteDay": "2999-12-31",
            "createDate": "2024-05-28 11:21:51",
            "updateDate": "2024-05-28 14:26:35"
        },
        {
            "writingSeq": 282,
            "writingCategoryKoreaName": "파트너 성장",
            "writingCategoryEnglishName": "Grow Together",
            "writingCategoryEtcName": "",
            "writingTitle": "가게 메뉴 사진과 행복한 추억까지 찰칵! ",
            "writingSubTitle": "2023 전국 방방곡곡 배민사진관",
            "writingImagePath": "https://woowahan-cdn.woowahan.com/new_resources/image/writing/03e6c862f6d8433fbda88ac40716a49c.jpg",
            "writingOpenStatusCode": "PUBLIC",
            "writingLinkUrl": "4e66682611fb44a4814a9b94e704ade0",
            "writingPublishReservationYn": false,
            "writingPublishDate": "2023-11-07 22:07:15",
            "writingTemporaryYn": false,
            "writingDeleteYn": false,
            "writingDeleteDay": "2999-12-31",
            "createDate": "2023-11-07 21:57:51",
            "updateDate": "2023-11-10 12:48:21"
        },
        {
            "writingSeq": 280,
            "writingCategoryKoreaName": "파트너 성장",
            "writingCategoryEnglishName": "Grow Together",
            "writingCategoryEtcName": "",
            "writingTitle": "지속가능한 행복의 시작",
            "writingSubTitle": "가치경영보고서 <배민스토리2023>  ",
            "writingImagePath": "https://woowahan-cdn.woowahan.com/new_resources/image/writing/9e1d6c1e977e41acb63e6ffdd51ba0a6.png",
            "writingOpenStatusCode": "PUBLIC",
            "writingLinkUrl": "",
            "writingPublishReservationYn": true,
            "writingPublishDate": "2023-09-06 08:30:00",
            "writingTemporaryYn": false,
            "writingDeleteYn": false,
            "writingDeleteDay": "2999-12-31",
            "createDate": "2023-09-06 04:19:16",
            "updateDate": "2023-09-06 09:32:42"
        },
        {
            "writingSeq": 275,
            "writingCategoryKoreaName": "배민그린",
            "writingCategoryEnglishName": "Baemin Green",
            "writingCategoryEtcName": "",
            "writingTitle": "일회용품 없이 배달을 즐기는 방법",
            "writingSubTitle": "다회용기 배달로 맛있는 음식도 먹고, 환경도 지키고!",
            "writingImagePath": "https://woowahan-cdn.woowahan.com/new_resources/image/writing/f7f55935cdd040ae9a6880c068972254.jpg",
            "writingOpenStatusCode": "PUBLIC",
            "writingLinkUrl": "",
            "writingPublishReservationYn": false,
            "writingPublishDate": "2023-08-25 16:56:37",
            "writingTemporaryYn": false,
            "writingDeleteYn": false,
            "writingDeleteDay": "2999-12-31",
            "createDate": "2023-08-25 16:56:39",
            "updateDate": "2024-03-19 10:45:28"
        },
        {
            "writingSeq": 274,
            "writingCategoryKoreaName": "배민그린",
            "writingCategoryEnglishName": "Baemin Green",
            "writingCategoryEtcName": "",
            "writingTitle": "우리가 함께 줄인 일회용품이 이만큼!",
            "writingSubTitle": "‘일회용 수저·포크 안 받기’ 기능의 성과를 공유합니다.",
            "writingImagePath": "https://woowahan-cdn.woowahan.com/new_resources/image/writing/2790f5787f03480a8dfd5c9da389c7ff.jpg",
            "writingOpenStatusCode": "PUBLIC",
            "writingLinkUrl": "",
            "writingPublishReservationYn": false,
            "writingPublishDate": "2023-08-25 16:48:25",
            "writingTemporaryYn": false,
            "writingDeleteYn": false,
            "writingDeleteDay": "2999-12-31",
            "createDate": "2023-08-25 16:48:26",
            "updateDate": "2023-08-28 16:50:28"
        },
        {
            "writingSeq": 273,
            "writingCategoryKoreaName": "배민아카데미",
            "writingCategoryEnglishName": "Baemin Academy",
            "writingCategoryEtcName": "",
            "writingTitle": "숫자로 보는 배민아카데미 이야기",
            "writingSubTitle": "배민아카데미 20만展, 입장하시겠습니까?",
            "writingImagePath": "https://woowahan-cdn.woowahan.com/new_resources/image/writing/f4b7fca566c54710b16052d64c012476.jpg",
            "writingOpenStatusCode": "PUBLIC",
            "writingLinkUrl": "",
            "writingPublishReservationYn": false,
            "writingPublishDate": "2023-08-25 16:35:36",
            "writingTemporaryYn": false,
            "writingDeleteYn": false,
            "writingDeleteDay": "2999-12-31",
            "createDate": "2023-08-25 16:35:38",
            "updateDate": "2023-08-25 16:38:00"
        },
        {
            "writingSeq": 271,
            "writingCategoryKoreaName": "파트너 성장",
            "writingCategoryEnglishName": "Grow Together",
            "writingCategoryEtcName": "",
            "writingTitle": "라이더가 안전하게 일할 수 있는 \n사회 안전망을 만들어가요",
            "writingSubTitle": "우아한 라이더 살핌기금 <4개년 사업 추진 보고서> 발행",
            "writingImagePath": "https://woowahan-cdn.woowahan.com/new_resources/image/writing/9e5d5d3597c04924b067e8dd3bc5b074.jpg",
            "writingOpenStatusCode": "PUBLIC",
            "writingLinkUrl": "cea83ba4f3874f28b9c5330dbebcd33f",
            "writingPublishReservationYn": false,
            "writingPublishDate": "2023-05-24 14:57:44",
            "writingTemporaryYn": false,
            "writingDeleteYn": false,
            "writingDeleteDay": "2999-12-31",
            "createDate": "2023-05-24 14:57:46",
            "updateDate": "2023-05-25 09:54:36"
        },
        {
            "writingSeq": 269,
            "writingCategoryKoreaName": "파트너 성장",
            "writingCategoryEnglishName": "Grow Together",
            "writingCategoryEtcName": "",
            "writingTitle": "외식업에 꼭 필요한 전문가를 만나요",
            "writingSubTitle": "배민외식업광장 ‘전문가 Q&A’",
            "writingImagePath": "https://woowahan-cdn.woowahan.com/new_resources/image/writing/135d7df2500e4f5aa801cc5f3363fe47.jpg",
            "writingOpenStatusCode": "PUBLIC",
            "writingLinkUrl": "8801e401523448678d96b4a4340b78e5",
            "writingPublishReservationYn": false,
            "writingPublishDate": "2023-05-19 12:40:53",
            "writingTemporaryYn": false,
            "writingDeleteYn": false,
            "writingDeleteDay": "2999-12-31",
            "createDate": "2023-05-19 12:40:56",
            "updateDate": "2023-05-24 13:50:12"
        },
        {
            "writingSeq": 267,
            "writingCategoryKoreaName": "파트너 성장",
            "writingCategoryEnglishName": "Grow Together",
            "writingCategoryEtcName": "",
            "writingTitle": "‘2023외식업트렌드’로 사장님들의 2023년을 함께 준비했어요!",
            "writingSubTitle": "2023 외식업트렌드",
            "writingImagePath": "https://woowahan-cdn.woowahan.com/new_resources/image/writing/a45b78d7adf741f88cf270fa9bc433dc.jpg",
            "writingOpenStatusCode": "PUBLIC",
            "writingLinkUrl": "65e47e81ace84740b863622c13f19079",
            "writingPublishReservationYn": false,
            "writingPublishDate": "2023-05-19 12:36:08",
            "writingTemporaryYn": false,
            "writingDeleteYn": false,
            "writingDeleteDay": "2999-12-31",
            "createDate": "2023-05-19 12:36:09",
            "updateDate": "2023-05-24 13:50:19"
        }
    ]

    const search = async (page: number = 1) => {
        setLoading(true)

        const response = await BlogService.search({
            page: page,
        })

        if (response.status === 200) {
            setPage(response.data.item)
        } else {
            Errors.AjaxError(response.data)
        }

        setLoading(false)
    }

    useEffect(() => {
        search()
    }, [])

    return (
        <MainLayout menuType={MenuType.Blog}>
            <Layout.Content className="blog">
                <Header />
                <Row gutter={[0, 24]} justify={"center"} style={{ marginTop: 88, marginBottom: 88, }}>
                    <Col span={24}>
                        <Flex justify="center">
                            <Typography.Title level={1} style={{ fontWeight: 700, }}>{'함께가치'}</Typography.Title>
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <Flex justify="center">
                            <Space direction="vertical" size={24}>
                                <Space direction="vertical" wrap style={{ textAlign: 'center' }} size={6}>
                                    <Typography.Text style={{ fontSize: 16, }}>{'지금까지의 성장은 결코 홀로 이뤄낸 것이 아닙니다.'}</Typography.Text>
                                    <Typography.Text style={{ fontSize: 16, }}>{'고객과 사장님, 라이더, 구성원과 함께 이뤄낸 눈부신 성과이지요.'}</Typography.Text>
                                    <Typography.Text style={{ fontSize: 16, }}>{`'함께'이기에 가능했고, '함께'이기에 가치 있었습니다.`}</Typography.Text>
                                </Space>
                                <Space direction="vertical" wrap style={{ textAlign: 'center' }} size={6}>
                                    <Typography.Text style={{ fontSize: 16, }}>{'앞으로도 배달의민족은 외식 산업이 건강하게 성장할 수 있도록'}</Typography.Text>
                                    <Typography.Text style={{ fontSize: 16, }}>{'음식이 주는 행복에서 소외 받는 사람이 없도록'}</Typography.Text>
                                    <Typography.Text style={{ fontSize: 16, }}>{'다음 세대를 위해 환경을 보전할 수 있도록 책임을 다하겠습니다.'}</Typography.Text>
                                </Space>
                            </Space>
                        </Flex>
                    </Col>
                </Row>
                <Row gutter={[0, 24]} justify={"center"} style={{ marginTop: 88, marginBottom: 88, }}>
                    <Col span={24}>
                        <Row>
                            <Col span={24} style={{ borderBottom: '1px solid #0000001a', paddingBottom: 20, }}>
                                <Flex justify="center">
                                    <Space size={4} split={"|"} style={{ color: '#d6d7da', fontSize: 20 }}>
                                        {menus.map((m) => {
                                            const isActivated = activate === m
                                            return (
                                                <Typography.Text
                                                    key={m}
                                                    className="menu"
                                                    style={{
                                                        color: isActivated ? '#232324' : '#d6d7da',
                                                    }}
                                                    onClick={() => {
                                                        setActivate(m)
                                                    }}
                                                >
                                                    {m}
                                                </Typography.Text>
                                            )
                                        })}
                                    </Space>
                                </Flex>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24} style={{ maxWidth: 980, }}>
                        <Spin spinning={isLoading}>
                            <Row gutter={[16, 16]}>
                                {page?.items?.map((r) => {
                                    return (
                                        <Col
                                            className="items"
                                            key={r.title}
                                            {...{ xs: 24, sm: 12 }}
                                            style={{
                                                minHeight: 480,
                                            }}
                                            onClick={() => {
                                                navigate(`/views/blog/${r.id}`)
                                            }}
                                        >
                                            {r.image && (
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        minHeight: 345,
                                                        overflow: 'hidden',
                                                        borderTopRightRadius: 6,
                                                        borderTopLeftRadius: 6,
                                                    }}
                                                >
                                                    <Image
                                                        preview={false}
                                                        src={r.image.middleUrl}
                                                        style={{
                                                            height: '100%',
                                                            minHeight: 345,
                                                            objectFit: 'cover'
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <Space
                                                direction="vertical"
                                                style={{
                                                    marginTop: 12,
                                                }}
                                                wrap
                                            >
                                                <Typography.Text
                                                    style={{
                                                        fontSize: 20,
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    {r.title}
                                                </Typography.Text>
                                                <Typography.Paragraph
                                                    style={{ marginBottom: 0, }}
                                                    ellipsis={{
                                                        rows: 2,
                                                    }}
                                                >
                                                    <Typography.Text
                                                        style={{
                                                            fontSize: 16,
                                                        }}
                                                    >
                                                        {r.subtitle}
                                                    </Typography.Text>
                                                </Typography.Paragraph>
                                                <Typography.Text
                                                    style={{
                                                        fontSize: 12,
                                                        color: '#F8B31A'
                                                    }}
                                                >
                                                    {r.blogTypeDisplayName}
                                                </Typography.Text>
                                            </Space>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Spin>
                    </Col>
                    <Col span={24} style={{ maxWidth: 980, marginTop: 24, }}>
                        <Flex justify="center">
                            <Pagination
                                defaultCurrent={1}
                                current={page?.currentPageIndex}
                                total={page?.totalItemCount}
                                pageSize={page?.size}
                                onChange={(page: number) => {
                                    search(page)
                                }}
                            />
                        </Flex>
                    </Col>
                </Row>
            </Layout.Content>
        </MainLayout>
    )
}